import React, { useMemo } from 'react'
import useWidth from 'src/hooks/window/useWidth'
import OpenVideo from 'src/components/OpenVideo'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { WIDTH_MD, WIDTH_XL } from 'src/styles/breakpoints'

import Play from '../../pages/o-inter/assets/images/transparent-play.svg'
import { Section } from './style'

type SectionDoubleVideoProps = {
  urlMp4: string;
  urlWeb: string;
  linkPlayVideo: string;
  styles?: string;
  titleSection?: string;
  imagesBg?: {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
    xxl?: string;
    xxxl?: string;
    xxll?: string;
  };
  dataLayerProps?: {
    section?: string;
    sectionName: string;
    elementName?: string;
  };
}

const SectionDoubleVideo = ({ urlMp4, urlWeb, linkPlayVideo, imagesBg, dataLayerProps, styles, titleSection }: SectionDoubleVideoProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const videoElement = useMemo(() => (
    <>
      <video
        key='introVideo'
        height='auto'
        width={width <= WIDTH_MD ? '1024' : '100%'}
        loop
        muted
        autoPlay
        style={{ marginLeft: width <= WIDTH_MD ? '-128px' : '0' }}
      >
        <source
          id='videoMp4'
          type='video/mp4'
          src={urlMp4}
        />
        <source
          id='videoWebm'
          type='video/webm'
          src={urlWeb}
        />
      </video>
    </>
  ), [ width ])

  return (
    <Section
      className={`position-relative d-md-flex align-items-center ${styles}`}
      sm={imagesBg?.sm}
      md={imagesBg?.md}
      lg={imagesBg?.lg}
      xl={imagesBg?.xl}
      xxl={imagesBg?.xxl}
      xxxl={imagesBg?.xxxl}
      xxll={imagesBg?.xxll}
    >
      {
      width >= WIDTH_MD &&
        <div className='position-absolute w-100 h-100 overflow-hidden'>
          {videoElement}
        </div>
     }
      <div className='container'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 col-md-7 col-lg-6 col-xl-7 text-center'>
            <OpenVideo
              link={linkPlayVideo}
              onClick={() => sendDatalayerEvent({
                section: dataLayerProps?.section ?? 'dobra_01',
                element_action: 'click video',
                element_name: dataLayerProps?.elementName ?? 'Assista ao vídeo',
                section_name: dataLayerProps?.sectionName ?? 'null',
                redirect_url: linkPlayVideo,
              })}
            >
              <img
                src={Play} width={width <= WIDTH_XL ? 67 : 100} height={width <= WIDTH_XL ? 67 : 100}
                alt='Assista ao vídeo'
                className='cursor-pointer'
                title='Assista ao vídeo'
              />
            </OpenVideo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SectionDoubleVideo
