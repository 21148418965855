import React from 'react'
import SectionDoubleVideo from 'src/components/SectionDoubleVideo'

import backgroundImageMobile from '../../assets/images/bg-mais-que-um-banco-mobile.jpg'
import backgroundImageMD from '../../assets/images/bg-mais-que-um-banco-tablet.jpg'
import backgroundImageLG from '../../assets/images/bg-mais-que-um-banco-desktop-lg.jpg'
import backgroundImageXL from '../../assets/images/bg-mais-que-um-banco-desktop-xl.jpg'

const Header = () => {
  return (
    <SectionDoubleVideo
      urlMp4='https://static.bancointer.com.br/videos/superapp/mp4/P1388005_1.mp4'
      urlWeb='https://static.bancointer.com.br/videos/superapp/mp4/P1388005_1.mp4'
      linkPlayVideo='https://www.youtube.com/embed/vgv65UTp2lQ?&autoplay=1'
      titleSection='Mais que um banco, um Super App que simplifica a sua vida.'
      styles='super-app'
      dataLayerProps={{
        sectionName: 'Mais que um banco, um Super App que simplifica a sua vida.',
      }}
      imagesBg={{
        sm: backgroundImageMobile,
        md: backgroundImageMD,
        lg: backgroundImageLG,
        xl: backgroundImageXL,
      }}
    />
  )
}

export default Header
