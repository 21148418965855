import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { gray } from 'src/styles/colors'

export const InterNaBolsaAmericana = styled.section`
  background-color: ${gray[100]};
  padding-top: 44px;
  padding-bottom: 44px;

  @media ${device.tablet} {
    padding-top: 96px;
    padding-bottom: 92px;
  }
  @media ${device.desktopLG} {
    padding-top: 95px;
    padding-bottom: 77px;
  }
  @media ${device.desktopLG} {
    padding-top: 105px;
    padding-bottom: 105px;
  }
 .btn {
   height: 48px;
   @media ${device.tablet} {
     max-width: 184px;
   }
 }
 .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      float: right;
    }
  }
`
