import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'

import Header from './section/header/_index'
import InteNaBolsaAmericana from '../produtos/sections/inter-na-bolsa-americana/_index'
import Beneficios from '../produtos/sections/beneficios/_index'
import SuperAppInter from '../produtos/sections/super-app-inter/_index'
import ShopInter from '../produtos/sections/shop-inter/_index'
import BancoInter from '../produtos/sections/banco-inter/_index'
import InterInvest from '../produtos/sections/inter-invest/_index'
import InterEmpresas from '../produtos/sections/inter-empresas/_index'
import InterSeguros from '../produtos/sections/inter-seguros/_index'
import EmprestimosFinanciamentos from '../produtos/sections/emprestimos-financiamento/_index'
import Cashback from '../produtos/sections/cashback/_index'

import { Wrapper } from '../produtos/style'
import pageContext from './pageContext.json'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDomReady from 'src/hooks/window/useDomReady'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import { Modal } from 'src/components/Modal'

const SuperApp = () => {
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_04',
    section_name: 'Quem tem conta no Inter tem soluções completas para todos os momentos.',
    element_action: 'click button',
    element_name: 'Quero ser inter',
  })

  const superAppModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm closeModal={() => setIsModalOpen(false)} dataLayer={dataLayer} />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {superAppModal}
        <Header />
        <InteNaBolsaAmericana />
        <Beneficios lang='pt' />
        <SuperAppInter lang='pt' setIsModalOpen={setIsModalOpen} setDataLayer={setDataLayer} dataLayer={dataLayer} />
        <BancoInter lang='pt' />
        <InterInvest lang='pt' />
        <ShopInter lang='pt' />
        <InterEmpresas />
        <InterSeguros />
        <EmprestimosFinanciamentos />
        <Cashback lang='pt' />
      </Layout>
    </Wrapper>
  )
}

export default SuperApp
