import React from 'react'
import Img from 'gatsby-image'

// Styles
import { InterNaBolsaAmericana } from './style'

import usePageQuery from './../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const InteNaBolsaAmericana = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <InterNaBolsaAmericana>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-xl-5 mb-4 mb-md-0'>
            <Img
              fluid={data.homeHerobannerInterNaBolsaAmericana.fluid}
              alt='Ilustração smartphone com o app do Inter aberto, ao lado de um passaporte laranja fictício do Inter, com o fundo em tons de laranja.'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 offset-xl-2'>
            <h2 className='fs-32 lh-40 fs-md-40 lh-md-50 fs-lg-48 lh-lg-60 fs-xl-64 lh-xl-80 fw-400 text-grayscale--500 mb-2 mb-md-3'>
              Inter na bolsa americana
            </h2>
            <p className='fs-18 lh-22 fw-400 text-grayscale--500 mb-5 mb-md-3'>
              Entenda tudo sobre a migração das ações do Inter.
            </p>
            <div>
              <a
                className='btn btn-orange--extra mx-auto text-white text-none'
                href='https://cloud.comunicacao.bancointer.com.br/conquiste-o-mundo'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    section_name: 'Inter na bolsa americana',
                    element_action: 'click button',
                    element_name: 'Saiba mais',
                    redirect_url: 'https://cloud.comunicacao.bancointer.com.br/conquiste-o-mundo',
                  })
                }}
              >
                Saiba mais
              </a>
            </div>
          </div>
        </div>
      </div>
    </InterNaBolsaAmericana>
  )
}

export default InteNaBolsaAmericana
