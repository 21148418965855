import styled, { css } from 'styled-components'
import { orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

type imagesBg = {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
  xxxl?: string;
  xxll?: string;
}

export const Section = styled.section`
  display: flex;
  align-items: flex-end;
  min-height: 407px;
  background-size: cover;
  overflow: hidden;
  
  ${(props: imagesBg) => props.sm && css`
    background: url(${props.sm}) no-repeat center center;
    background-size: cover;
  `}

  @media ${device.tablet} {
    min-height: 576px;
    ${(props: imagesBg) => props.md && css`
      background: url(${props.md}) no-repeat center center;
      background-size: cover;
    `}
  }

  @media ${device.desktopLG} {
    ${(props: imagesBg) => props.lg && css`
      background: url(${props.lg}) no-repeat center center;
      background-size: cover;
    `}
  }  
  
  @media ${device.desktopXL} {
    min-height: 801.56px;
    ${(props: imagesBg) => props.xl && css`
      background: url(${props.xl}) no-repeat center center;
      background-size: cover;
    `}
  }

  @media ${device.desktopXXL} {
    min-height: 801.56px;
    ${(props: imagesBg) => props.xxl && css`
      background: url(${props.xxl}) no-repeat center center;
      background-size: cover;
    `}
  }

  @media ${device.desktopXXXL} {
    min-height: 891.56px;
    ${(props: imagesBg) => props.xxxl && css`
      background: url(${props.xxxl}) no-repeat center center;
    `}
  }

  @media ${device.desktopXXLL} {
    min-height: 1071.56px;
    ${(props: imagesBg) => props.xxll && css`
      background: url(${props.xxll}) no-repeat center center;
    `}
  }

  p {
    font-family: "citrina";
    font-size: 40px;
    line-height: 48px;

    @media ${device.desktopXL} {
      font-size: 48px;
      line-height: 57px;
    }
    @media ${device.desktopXXXL} {
      font-size: 64px;
      line-height: 77px;
    }
  }

  video {
    filter: brightness(40%);
  }
  
  button {
    background: ${orange.extra};
    max-width: 110px;
    width: 100%;
    height: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 10px; 
    border-radius: 8px;  

    @media ${device.tablet} {
      max-width: 323px;
      height: 49px;
    }
  }

  &.super-app {
    min-height: 516px;

    @media ${device.tablet} {
      min-height: 576px;
    }
    @media ${device.desktopXL} {
      min-height: 801.56px;
    }
    @media ${device.desktopXXXL} {
      min-height: 890px;
    }
    @media ${device.desktopXXLL} {
      min-height: 1071.56px;
    }

    p {
      font-family: "Inter";
      font-size: 24px;
      line-height: 30px;

      @media ${device.desktopLG} {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
`
